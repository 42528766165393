import { Injectable, inject } from '@angular/core';
import { DivisionService } from '@app/lib/services/division.service';
import { LOGOUT_TEARDOWN_TOKEN } from '@app/shared/tokens/logout-teardown-token';
import { Id } from '@pi/pi-common/src/ui-dtos/id';
import { VerifyDivisionResponse } from '@pi/pi-common/src/ui-dtos/verify-division.dtos';

@Injectable({
    providedIn: 'root'
})
export class DivisionVerificationCacheService {
    private divisionVerificationCache: { [divisionId: Id]: VerifyDivisionResponse } = {};

    private readonly divisionService = inject(DivisionService);

    public async get(planSponsorId: Id, divisionId: Id): Promise<VerifyDivisionResponse> {
        if (!this.divisionVerificationCache[divisionId]) {
            const verifyDivisionResponse = await this.divisionService.verifyDivision(planSponsorId, divisionId);
            this.divisionVerificationCache = {
                ...this.divisionVerificationCache,
                [divisionId]: verifyDivisionResponse
            };
        }
        return this.divisionVerificationCache[divisionId];
    }

    public delete(divisionId: Id) {
        delete this.divisionVerificationCache[divisionId];
    }

    public clear() {
        this.divisionVerificationCache = {};
    }
}

export const DIVISION_VERIFICATION_CACHE_TEARDOWN = {
    provide: LOGOUT_TEARDOWN_TOKEN,
    multi: true,
    deps: [DivisionVerificationCacheService],
    useFactory: (divisionVerificationCache: DivisionVerificationCacheService) => () => divisionVerificationCache.clear()
};
